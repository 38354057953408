nav {
  z-index: 10;
  background-color: #fff;
  justify-content: space-between;
  height: 70px;
  padding: 0 5%;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

nav .logo {
  height: 70px;
  padding: 10px 0;
}

nav .logo-wrapper {
  gap: 20px;
  display: flex;
}

@media screen and (width <= 500px) {
  .logo {
    padding: 10px;
  }
}

nav .links {
  justify-content: space-around;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

nav .links li {
  list-style: none;
}

nav .links a {
  color: #585151;
  padding: 1em;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

nav .links a:hover {
  opacity: .7;
}

#nav-toggle {
  display: none;
  position: absolute;
}

nav .icon-burger {
  display: none;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

nav .icon-burger .line {
  background-color: #3b3b3b;
  border-radius: 3px;
  width: 30px;
  height: 5px;
  margin: 5px;
  transition: all .3s ease-in-out;
}

@media screen and (width <= 1025px) {
  nav .links a {
    font-size: 13.5px;
  }
}

@media screen and (width <= 900px) {
  nav .links {
    float: none;
    z-index: 9;
    box-sizing: border-box;
    background-color: #202020;
    flex-direction: column;
    justify-content: space-evenly;
    width: auto;
    height: auto;
    transition: all .5s ease-in-out;
    position: fixed;
    inset: 70px 0 100%;
    overflow: hidden;
  }

  nav .links a {
    color: #d4d4d4;
    font-size: 20px;
  }

  nav :checked ~ .links {
    bottom: -10px;
  }

  nav .icon-burger {
    display: block;
  }

  nav :checked ~ .icon-burger .line:first-child {
    transform: translateY(10px)rotate(225deg);
  }

  nav :checked ~ .icon-burger .line:nth-child(3) {
    transform: translateY(-10px)rotate(-225deg);
  }

  nav :checked ~ .icon-burger .line:nth-child(2) {
    opacity: 0;
  }
}
/*# sourceMappingURL=index.d9b19adf.css.map */
