// NARBAR CSS STARTS
nav {
	position: sticky;
	display: flex;
	justify-content: space-between;
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;
	padding: 0 5%;
	height: 70px;
	background-color: rgb(255, 255, 255);
}
nav .logo {
	// float: left;
	height: 70px;
	padding: 10px 0px;
}

nav .logo-wrapper{
display: flex;
gap: 20px;

}

@media screen and (max-width: 500px) {
	.logo{
		padding: 10px 10px;
	}
}

nav .links {
	padding: 0;
	margin: 0;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
nav .links li {
	list-style: none;
}
nav .links a {
	display: block;
	padding: 1em;
	font-size: 16px;
	font-weight: 500;
	color: rgb(88, 81, 81);
	text-decoration: none;
}

nav .links a:hover {
  opacity: 0.7;
}


#nav-toggle {
	position: absolute;
	display: none;
}
nav .icon-burger {
	display: none;
	position: absolute;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
}
nav .icon-burger .line {
	width: 30px;
	height: 5px;
	background-color: rgb(59, 59, 59);
	margin: 5px;
	border-radius: 3px;
	transition: all .3s ease-in-out;
}

@media screen and (max-width: 1025px) {
	nav .links a {
		font-size: 13.5px;
    // color: rgb(212, 212, 212);
	}
}
@media screen and (max-width: 900px) {
	nav .links {
		float: none;
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		top: 70px;
		bottom: 100%;
		width: auto;
		height: auto;
		flex-direction: column;
		justify-content: space-evenly;
		background-color: rgb(32, 32, 32);
		overflow: hidden;
		box-sizing: border-box;
		transition: all .5s ease-in-out;
	}
	nav .links a {
		font-size: 20px;
    color: rgb(212, 212, 212);
	}
	nav :checked ~ .links {
		bottom: -10px;
	}
	nav .icon-burger {
		display: block;
	}
	nav :checked ~ .icon-burger .line:nth-child(1) {
		transform: translateY(10px) rotate(225deg);
	}
	nav :checked ~ .icon-burger .line:nth-child(3) {
		transform: translateY(-10px) rotate(-225deg);
	}
	nav :checked ~ .icon-burger .line:nth-child(2) {
		opacity: 0;
	}
	
}
// NAVBAR CSS ENDS